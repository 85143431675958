import leader1 from "../../../../assets/points-leaderboard/leader1.svg";
import leader2 from "../../../../assets/points-leaderboard/leader2.svg";
import leader3 from "../../../../assets/points-leaderboard/leader3.svg";
import leader4 from "../../../../assets/points-leaderboard/leader4.svg";

export const data = [
  { id: 1, name: "Dreed", bonus: 2000, image: leader1 },
  { id: 2, name: "Killer", bonus: 1500, image: leader2 },
  { id: 3, name: "Tiana", bonus: 1000, image: leader3 },
  { id: 4, name: "Noob", bonus: 500, image: leader4 },
  { id: 5, name: "Alex", bonus: 500, image: leader3 },
  { id: 6, name: "Sam", bonus: 50, image: leader1 },
];

export const winners = ["🥇", "🥈", "🥉"];
