import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import WellcomeScreen from "../components/WellcomeScreen/WellcomeScreen";
import { FooterNavigation } from "../components";

import styles from "./styles.module.scss";

const navbarPaths = [
  "/",
  "?/?token=",
  "/upgrade",
  "/pvp",
  "/quest",
  "/profile",
];

const Layout = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);

  const footerOutletHandler =
    navbarPaths.includes(location.pathname) ||
    navbarPaths.includes(location.search);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoaded(true);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isLoaded) {
    return <WellcomeScreen />;
  }

  return footerOutletHandler ? (
    <div className={styles.wrapper}>
      <div id="outlet" className={styles.outlet}>
        <Outlet />
      </div>
      <div id="footer" className={styles.footerContainer}>
        <FooterNavigation />
      </div>
      <div className={styles.gradient} />
    </div>
  ) : (
    <div className={styles.wrapper2}>
      <Outlet />
    </div>
  );
};

export default Layout;
