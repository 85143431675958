import { useSelector } from "react-redux";

import styles from "./styles.module.css";

export const Level = () => {
  const { user, levelConfig } = useSelector(({ account }) => account);

  const level = user?.data?.level;

  const roundHandler = () => {
    return levelConfig?.round_to_complete === user?.data?.round
      ? "Final round"
      : `Round ${user?.data?.round}`;
  };
  return (
    <div className={styles.lvlContainer}>
      <div className={styles.text}>
        <span className={styles.spn}>Lvl</span>
        <span className={styles.spn}>{level}</span>
      </div>
      <div className={styles.subtext}>{roundHandler()}</div>
      <div className={styles.shape} />
    </div>
  );
};
