import React from "react";

import { data, winners } from "../../mock-data";

import urls from "../../../../../../utils/imageUrls";

import styles from "./styles.module.scss";
const UsersList = () => {
  return (
    <ul className={styles.usersList}>
      {data.map((item, i) => {
        const isWinner = winners.find((_, index) => index === i);

        return (
          <li
            key={item.id}
            className={styles.user}
            style={{ backgroundColor: isWinner ? "#28283f" : "#232332" }}
          >
            <section className={styles.firstBlock}>
              <img src={item.image} alt="" />
              <div className={styles.nameAndBonus}>
                <span className={styles.name}>{item.name}</span>
                <div className={styles.bonus}>
                  <span className={styles.coinContainer}>
                    <img src={urls.gyw$Coin} alt="" className={styles.coin} />
                  </span>
                  <span className={styles.bonusText}>+ {item.bonus}</span>
                </div>
              </div>
            </section>

            <section
              className={styles.positionInList}
              style={{
                backgroundColor: !isWinner && "#3e3e4b",
                borderRadius: !isWinner && "50%",
                fontSize: isWinner ? "26px" : "14px",
                fontWeight: isWinner ? 600 : 500,
              }}
            >
              {isWinner ? isWinner : i + 1}
            </section>
          </li>
        );
      })}
    </ul>
  );
};

export default UsersList;
