import React from "react";

import c1 from "../../../../assets/roll-circle2/c1.svg";

import styles from "./styles.module.scss";

const DashedCircle = ({ isAnimationActive }) => {
  return (
    <img
      src={c1}
      alt=""
      className={
        isAnimationActive ? `${styles.c1} ${styles.c1Animated}` : styles.c1
      }
    />
  );
};

export default DashedCircle;
