import { useEffect, useState } from "react";

import ModalButton from "../ModalButton/ModalButton";

import { useDispatch } from "react-redux";
import { refetchProfile } from "../../redux/actions/Account";
import { getGameConfigHandler } from "../../redux/actions/GameConfig";
import api from "../../api";

import gywPackReward from "../../assets/gywPackReward.svg";
import rewardCup from "../../assets/rewardCup.svg";

import styles from "./styles.module.css";

export const RoundClearedModal = ({ curProgress }) => {
  const dispatch = useDispatch();

  const claimRound = () => {
    try {
      api.claimRound().then(() => {
        dispatch(refetchProfile());
        dispatch(getGameConfigHandler());
      });
    } catch (error) {
      console.error(`Error message: ${error}`);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const isRoundClearedHandler = () => {
    if (curProgress?.completed && !curProgress?.claimed) {
      setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    isRoundClearedHandler();
  }, [curProgress]);

  return (
    <div className={isOpen ? styles.wrapper : styles.closed}>
      <div className={styles.modal}>
        <h3 className={styles.title}>Round CLEARED</h3>
        <div className={styles.content}>
          {curProgress?.prizes?.map((pr, i) => (
            <div key={i} className={styles.rewardsContainer}>
              <div style={{ width: "38px", height: "38px" }}>
                <img
                  src={pr.valueId === "RP" ? rewardCup : gywPackReward}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={styles.rewardTitle}>
                  {pr.title.split(" ")[1]}
                </span>
                <span className={styles.reward}>{pr.title.split(" ")[0]}</span>
              </div>
            </div>
          ))}
        </div>
        <div>
          <ModalButton onClick={claimRound} />
        </div>
      </div>
    </div>
  );
};
