import { useNavigate } from "react-router-dom";

import { RewardPool } from "./components/index.js";
import UsersList from "./components/UsersList/UsersList.jsx";

import cross from "../../../../assets/cross.svg";

import styles from "./styles.module.scss";
// import api from "../../../../api/index.js";
// import { useEffect, useState } from "react";

export const TokenPoints = () => {
  // const [data, setData] = useState([]);
  const navigate = useNavigate();
  // console.log(data, "data");

  // const getData = () => {
  //   // try {
  //   //   api
  //   //     .getUserSummary()
  //   //     .then((res) => {
  //   //       if (res.status === 200) {
  //   //         setData(res);
  //   //       }
  //   //     })
  //   //     .catch((error) => console.log(error));
  //   // } catch (error) {
  //   //   return console.log(error);
  //   // }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);
  return (
    <>
      <div className={styles.background} />
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <span className={styles.text}>POINTS LEADERBOARD</span>
          <img
            src={cross}
            alt=""
            className={styles.icon}
            onClick={() => navigate("/info")}
          />
        </header>

        <section className={styles.rewardPool}>
          <RewardPool />
          <div className={styles.subtitle}>
            *Reward pool is distributed everyday at 00:00 UTC
          </div>
        </section>
        <UsersList />
      </div>
    </>
  );
};
