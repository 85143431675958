import { combineReducers } from "redux";

import account from "./Account";
import common from "./Common";
import dynamic from "./Dynamic";
import limits from "./Limits";
import gameConfig from "./GameConfig";

const app = combineReducers({
  account,
  common,
  dynamic,
  limits,
  gameConfig,
});

export default app;
