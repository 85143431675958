import { useSelector } from "react-redux";

import arrow from "../../../../assets/roll-circle2/arrow.svg";
import arrowL from "../../../../assets/roll-circle2/arrowL.svg";

import styles from "./styles.module.scss";

const Arrows = ({ isAnimationActive }) => {
  const { user } = useSelector(({ account }) => account);

  const circleAmount = Array(user?.data?.level)?.length;

  const widthHandler = () => {
    if (circleAmount === 1) return "60px";
    else if (circleAmount === 2) return "40px";
    return "20px";
  };

  const currWidth = widthHandler();

  return (
    <div className={`${isAnimationActive ? styles.arrows : styles.noArrows}`}>
      <img
        src={arrowL}
        alt=""
        className={styles.arrowL}
        style={{ "--length": currWidth }}
      />
      <img
        src={arrow}
        alt=""
        className={styles.arrowR}
        style={{ "--length": currWidth }}
      />
    </div>
  );
};

export default Arrows;
