import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Arrows from "./components/Arrows/Arrows";
import WhiteCircle from "./components/WhiteCircle/WhiteCircle";
import CenterThings from "./components/CenterThings/CenterThings";
import Dashes from "./components/Dashes/Dashes";
import MidCircle from "./components/MidCircle/MidCircle";
import DashedCircle from "./components/DashedCircle/DashedCircle";

import api from "../../api";
import { refetchProfile } from "../../redux/actions/Account";
import { getGameConfigHandler } from "../../redux/actions/GameConfig";

import tap from "../../assets/roll-circle/tap.svg";

import styles from "./styles.module.scss";

export const RollCircle = ({ curProgress, index }) => {
  const { user } = useSelector(({ account }) => account);
  const { gameConfig } = useSelector(({ gameConfig }) => gameConfig);

  const dispatch = useDispatch();

  const [isPressed, setIsPressed] = useState(false);
  const [loseWin, setLoseWin] = useState(false);
  const [resultTitle, setResultTitle] = useState("");

  const ammos = user?.data?.curBullets;
  const userBulletsLimit = gameConfig?.ammo?.[0]?.value;
  const severalCircles = Array(user?.data?.level)?.length > 1;

  const select = () => {
    // if user doesn't have enough bullets to roll circle, return
    const pressedTargets = curProgress?.targets?.some(
      (targetNum) => targetNum === index + 1
    );
    if (ammos < userBulletsLimit || pressedTargets) return;
    setLoseWin(true);

    try {
      setTimeout(() => {
        api.shootTarget(index + 1).then((response) => {
          if (response?.data) {
            dispatch(refetchProfile());
            dispatch(getGameConfigHandler());
            setIsPressed(true);
            if (curProgress?.completed && !curProgress?.claimed) {
              setIsPressed(false);
            }
          }
        });
      }, 2000);
    } catch (error) {
      console.error(`Select error: ${error}`);
    }
  };

  useEffect(() => {
    const havePrize = curProgress?.prizes?.filter(
      (p) => p.targetId === index + 1
    ).length;

    if (havePrize > 0) {
      const newTitle = curProgress?.prizes?.filter(
        (p) => p.targetId === index + 1
      )?.[0].title;
      setResultTitle(newTitle);
    } else {
      setResultTitle("😓");
    }
  }, [curProgress, select]);

  useEffect(() => {
    const targetsHandler = curProgress?.targets?.some(
      (target) => target === index + 1
    );
    const isNewLevel = user?.data?.progress?.some(
      (r) =>
        r?.cycle === user?.data?.cycle &&
        r?.level === user?.data?.level &&
        (!r.claimed || !r.completed)
    );

    if (targetsHandler && isNewLevel) {
      setLoseWin(true);
      setIsPressed(false);
    } else setLoseWin(false);
  }, [curProgress]);

  return (
    <div
      className={
        loseWin
          ? `${styles.wrapperAnimated} ${styles.wrapper}`
          : `${styles.wrapper}`
      }
      style={{ position: "relative" }}
      onClick={select}
    >
      <DashedCircle isAnimationActive={loseWin} />
      <WhiteCircle isAnimationActive={loseWin} />
      <MidCircle isAnimationActive={loseWin} />
      <CenterThings isAnimationActive={loseWin} />
      <Dashes isAnimationActive={loseWin} />
      <Arrows isAnimationActive={loseWin} />

      <div className={styles.resultBox}>
        {curProgress?.targets?.indexOf(index + 1) >= 0 && !isPressed ? (
          <div className={styles.rollingResult}>{resultTitle}</div>
        ) : (
          <button className={styles.button}>
            {!loseWin && (
              <img
                className={
                  severalCircles ? styles.buttonImgSM : styles.buttonImg
                }
                src={tap}
                alt=""
              />
            )}
          </button>
        )}
      </div>
    </div>
  );
};
