import React from "react";

import { Information } from "../../../../components";

import styles from "./styles.module.scss";

const InfoBlock = () => {
  return (
    <div className={styles.informationContainer}>
      <Information />
      <Information bullets={true} />
    </div>
  );
};

export default InfoBlock;
