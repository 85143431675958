import React from "react";

import coinsBg from "../../../../../../assets/points-leaderboard/coins-bg.svg";
import styles from "./styles.module.scss";
import urls from "../../../../../../utils/imageUrls";
import { useCountdown } from "../../../../../../utils/hooks/useCountDown";

export const RewardPool = () => {
  const [hours, minutes, seconds] = useCountdown();

  const timeMap = [
    { id: 1, time: hours, name: "hour" },
    { id: 2, time: minutes, name: "min" },
    { id: 3, time: seconds, name: "sec" },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.linearBorder} />
      <img src={coinsBg} alt="" />
      <img src={urls.gyws_pack} alt="" className={styles.coinsIcon} />

      <div className={styles.rewardAmount}>100000</div>
      <div className={styles.timerWrapper}>
        {timeMap.map((item) => (
          <div key={item.id} className={styles.time}>
            <span className={styles.nums}>{item.time}</span>
            <span className={styles.name}>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
