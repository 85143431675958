import { useSelector } from "react-redux";

import HeaderBlocks from "./HeaderBlocks/HeaderBlocks";

export const Header = () => {
  const { user } = useSelector(({ account }) => account);
  const { gameConfig } = useSelector(({ gameConfig }) => gameConfig);

  const userBulletsPerHour = gameConfig?.bullets?.[0]?.value;

  return (
    <header
      style={{
        padding: "0px 0 10px 0",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <HeaderBlocks position={"left"} userBulletsPerHour={userBulletsPerHour} />
      <HeaderBlocks bonusBalance={user?.data?.bonusBalance} />
    </header>
  );
};
