import auth from "../../services/auth";
import api from "../../api";

import {
  UPDATE_PROFILE,
  LOGIN_SUCCESS,
  SILENT_LOGIN,
  LOGOUT,
  GET_LEVEL_CONFIG,
} from "../constants/actionTypes";

const login = (username, password) => async (dispatch) => {
  const result = await auth.loginWithEmailAndPassword(username, password);
  const { access_token } = result;
  const user = { access_token, ...auth.decodeToken(access_token) };

  setUserData(access_token);

  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      user,
    },
  });
};

const setUserData = (token) => (dispatch) => {
  if (token) {
    api
      .getUserSummary()
      .then(({ data }) => {
        const user = { access_token: token, data: { ...data } };

        if (!user.data.calibrationGames) {
          user.data.calibrationGames = [];
        }

        dispatch({
          type: SILENT_LOGIN,
          payload: {
            user,
          },
        });
      })
      .catch(() => {
        auth.logout();

        dispatch({
          type: SILENT_LOGIN,
          payload: {
            user: {},
          },
        });
      });
  }
};

const updateProfile = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      ...data,
    },
  });
};

const refetchProfile = () => async (dispatch) => {
  api
    .getUserSummary()
    .then(({ data }) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...data,
        },
      });
    })
    .catch(() => {
      auth.logout();

      dispatch({
        type: SILENT_LOGIN,
        payload: {
          user: {},
        },
      });
    });
};
const refetchInventory = () => async (dispatch) => {
  api
    .getPlayerInventory()
    .then(({ data }) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...data,
        },
      });
    })
    .catch(() => {
      auth.logout();

      dispatch({
        type: SILENT_LOGIN,
        payload: {
          user: {},
        },
      });
    });
};

const logout = () => async (dispatch) => {
  auth.logout();

  dispatch({
    type: LOGOUT,
  });
};

const getLevelConfig = () => (dispatch) => {
  api
    .getLevelConfig()
    .then(({ data }) => {
      dispatch({
        type: GET_LEVEL_CONFIG,
        payload: data,
      });
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.log(`GET_LEVEL_CONFIG: ${error}`));
};

export {
  login,
  logout,
  setUserData,
  updateProfile,
  refetchProfile,
  refetchInventory,
  getLevelConfig,
};
