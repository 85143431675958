import React from "react";

import c2 from "../../../../assets/roll-circle2/c2.svg";
import c3 from "../../../../assets/roll-circle2/c3.svg";

import styles from "./styles.module.scss";
const WhiteCircle = ({ isAnimationActive }) => {
  return (
    <>
      <img
        src={c2}
        alt=""
        className={
          isAnimationActive ? `${styles.c2} ${styles.c3Animated}` : styles.c2
        }
      />
      <img
        src={c3}
        alt=""
        className={
          isAnimationActive ? `${styles.c3} ${styles.c4Animated}` : styles.c3
        }
      />
      <img
        src={c3}
        alt=""
        className={
          isAnimationActive ? `${styles.c3} ${styles.c5Animated}` : styles.c3
        }
      />
      <img
        src={c3}
        alt=""
        className={
          isAnimationActive ? `${styles.c3} ${styles.c6Animated}` : styles.c3
        }
      />
    </>
  );
};

export default WhiteCircle;
