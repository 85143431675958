import { NotFound } from "../components";
import {
  InfoPage,
  PlayPage,
  ProfilePage,
  PvpPage,
  QuestPage,
  TokenPoints,
  UpgradePage,
} from "../scenes";

export const routerConfig = [
  { path: "/*", element: <NotFound /> },
  { path: "/", element: <PlayPage /> },
  { path: "/upgrade", element: <UpgradePage /> },
  { path: "/pvp", element: <PvpPage /> },
  { path: "/quest", element: <QuestPage /> },
  { path: "/profile", element: <ProfilePage /> },
  { path: "/info", element: <InfoPage /> },
  { path: "info/token-points-leaderboard", element: <TokenPoints /> },
];
