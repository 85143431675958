import React from "react";

import c4 from "../../../../assets/roll-circle2/staticCircle.svg";

import styles from "./styles.module.scss";

const MidCircle = ({ isAnimationActive, onClick }) => {
  return (
    <img
      src={c4}
      alt=""
      className={`${
        isAnimationActive
          ? `${styles.midCircle} ${styles.midAnimated}`
          : styles.midCircle
      }`}
      onClick={onClick}
    />
  );
};

export default MidCircle;
