import dashes from "../../../../assets/roll-circle2/thickDashes.svg";

import styles from "./styles.module.scss";

const Dashes = ({ isAnimationActive }) => {
  return (
    <div
      className={
        isAnimationActive ? styles.dashesWrapperAnim : styles.dashesWrapper
      }
    >
      <img
        src={dashes}
        alt=""
        className={
          isAnimationActive ? styles.dashesIconAnim : styles.dashesIcon
        }
      />
    </div>
  );
};

export default Dashes;
