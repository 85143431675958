import React, { memo } from "react";
import { wellcomeScreenDB } from "./index.db";

import ListItems from "./components/ListItems";

import styles from "./styles.module.css";

const WellcomeScreen = () => {
  return (
    <div className={styles.wrapper}>
      <div id="title">
        <h1 style={{ marginBottom: 0 }}>Roadmap</h1>
      </div>
      {/* roadmap list  */}
      <ul className={styles.list}>
        {wellcomeScreenDB.map((item, i) => (
          <ListItems key={i} item={item} />
        ))}
      </ul>
      {/* background and soldier image  */}
      <div className={styles.linearGradientBg} />
    </div>
  );
};

WellcomeScreen.displayName = "WellcomeScreen";

export default memo(WellcomeScreen);
