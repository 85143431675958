import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../layout/Layout";

import { routerConfig } from "./routerConfige";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routerConfig?.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
