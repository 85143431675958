import play from "../../assets/footer/play.svg";
import upgrade from "../../assets/footer/upgrade.svg";
import pvp from "../../assets/footer/pvp.svg";
import quest from "../../assets/footer/quest.svg";
import profile from "../../assets/footer/profile.svg";

import playActive from "../../assets/footer/playActive.svg";
import upgradeActive from "../../assets/footer/upgradeActive.svg";
import pvpActive from "../../assets/footer/pvpActive.svg";
import questActive from "../../assets/footer/questActive.svg";
import profileActive from "../../assets/footer/profileActive.svg";

export const mapa = [
  { name: "play", icon: play, activeIcon: playActive, path: "/" },
  {
    name: "upgrade",
    icon: upgrade,
    activeIcon: upgradeActive,
    path: "/upgrade",
  },
  { name: "pvp", icon: pvp, activeIcon: pvpActive, path: "/pvp" },
  { name: "quest", icon: quest, activeIcon: questActive, path: "/quest" },
  {
    name: "profile",
    icon: profile,
    activeIcon: profileActive,
    path: "/profile",
  },
];
