import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalButton from "../ModalButton/ModalButton";

import api from "../../api";
import urls from "../../utils/imageUrls";
import { getLevelConfig, refetchProfile } from "../../redux/actions/Account";
import { getGameConfigHandler } from "../../redux/actions/GameConfig";

import styles from "./styles.module.css";

export const LevelClearedModal = () => {
  const { user, levelConfig } = useSelector(({ account }) => account);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [totalTargets, setTotalTargets] = useState(0);

  const totalTargetsHandler = () => {
    const lvlRounds = user?.data?.progress?.filter(
      (p) => p?.cycle === user?.data?.cycle && p?.level === user?.data?.level
    );

    const total = lvlRounds?.reduce((acc, cur) => {
      return acc + cur?.targets?.length;
    }, 0);

    setTotalTargets(total);
  };

  useEffect(() => {
    totalTargetsHandler();
  }, [user]);

  const getlevel = () => {
    if (!isOpen?.length) {
      return dispatch(getLevelConfig());
    }
    return;
  };

  const claimLevel = () => {
    try {
      api.claimLevel().then((response) => {
        if (response.status === 200) {
          dispatch(refetchProfile());
          dispatch(getGameConfigHandler());
          dispatch(getLevelConfig());
          setIsOpen(false);
        }
      });
    } catch (error) {
      console.error(`Error message: ${error}`);
    }
  };

  useEffect(() => {
    const newRoud = user?.data?.progress?.filter(
      (r) =>
        r?.cycle === user?.data?.cycle &&
        r?.level === user?.data?.level &&
        (!r.claimed || !r.completed)
    );
    if (newRoud?.length === 0) {
      setIsOpen(true);
    }
  }, [user?.data?.progress]);

  useEffect(() => {
    if (isOpen) {
      getlevel();
    }
  }, [isOpen]);

  return (
    <div className={`${!isOpen ? styles.closed : styles.wrapper}`}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <h3 className={styles.title}>LEVEL CLEARED</h3>

          <section className={styles.rewards}>
            <div>
              <span className={styles.rewTitle}>Lvl bonus:</span>
              <span className={styles.curBonus}>
                + {levelConfig?.lvl_bonus}
              </span>
              <span className={styles.explain}>
                ({parseInt(levelConfig?.rounds_to_complete / totalTargets)}*
                100)
              </span>
            </div>
            <div>
              <span className={styles.rewTitle}>Accuracy bonus:</span>
              <span className={styles.curBonus}>
                + {levelConfig?.accuracy_bonus}
              </span>
              <span className={styles.explain}>
                ({parseInt(levelConfig?.rounds_to_complete / totalTargets)}*
                100%)
              </span>
            </div>
            {levelConfig?.upgrate_bonuse && (
              <div>
                <span className={styles.rewTitle}>Upgrade bonus:</span>
                <span className={styles.curBonus}>
                  {levelConfig?.upgrate_bonus}
                </span>
                <span className={styles.explain}>( 100*0 )</span>
              </div>
            )}
          </section>

          <section className={styles.rewIcon}>
            <div className={styles.rewBackground} />
            <img
              style={{ width: "49px", height: "49px", zIndex: 2 }}
              src={urls.gyws_pack}
              alt=""
            />
            <span className={styles.rewAmount}>{levelConfig?.lvl_bonus}</span>
          </section>

          <section className={styles.button}>
            <ModalButton onClick={claimLevel} />
          </section>
        </div>
      </div>
    </div>
  );
};
