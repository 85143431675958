import { COMMON_DATA } from "../constants/actionTypes";

const initialState = {};

const Common = (state = initialState, { type, payload }) => {
  switch (type) {
    case COMMON_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default Common;
