import api from "../../api";
import { GET_GAME_CONFIG } from "../constants/actionTypes";

export const getGameConfigHandler = () => (dispatch) => {
  api
    .getGameConfig()
    .then(({ data }) => {
      dispatch({
        type: GET_GAME_CONFIG,
        payload: data,
      });
    })
    .catch((error) => console.error(`Error in getGameConfigHandler: ${error}`));
};
