import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import InfoBlock from "./components/InfoBlock/InfoBlock";
import LevelBlock from "./components/LevelBlock/LevelBlock";
import CircleTargets from "./components/CircleTargets/CircleTargets";
import { Header, LevelClearedModal, RoundClearedModal } from "../../components";

import styles from "./styles.module.scss";

export const PlayPage = () => {
  const { user } = useSelector(({ account }) => account);

  const [curProgress, setCurProgress] = useState({});

  const getProgressHandler = () => {
    const progress = user?.data?.progress?.filter((obj) => {
      if ((!obj.claimed && obj.completed) || !obj.completed) {
        return obj;
      }
    });
    setCurProgress(progress?.[0]);
  };

  useEffect(() => {
    getProgressHandler();
  }, [user]);

  return (
    <div className={styles.wrapper}>
      <Header />
      <LevelBlock />
      <section className={styles.secondSection}>
        <CircleTargets curProgress={curProgress} />
        <InfoBlock />
      </section>
      <RoundClearedModal curProgress={curProgress} />
      <LevelClearedModal />
    </div>
  );
};
