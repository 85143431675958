import { GoldCup, Level } from "../../../../components";

import styles from "./styles.module.scss";

const LevelBlock = () => {
  return (
    <section className={styles.firstSection}>
      <Level />
      <GoldCup />
    </section>
  );
};

export default LevelBlock;
