import { useEffect, useState } from "react";

const getEndOfDayUTC = () => {
  const now = new Date(); // Get current date
  now.setUTCHours(23, 59, 59, 999); // Set the time to 23:59:59.999 (end of the day in UTC)
  return now.toISOString(); // Return as ISO 8601 string
};

const targetDate = getEndOfDayUTC();

const useCountdown = () => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  let hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  // Add leading zero if the value is less than 10
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return [hours, minutes, seconds];
};

export { useCountdown };
