import { StrictMode, useEffect } from "react";
import { Provider } from "react-redux";

import Auth from "./utils/Auth/Auth";
import Router from "./routes/Router";

import store from "./redux/store";

function App() {
  useEffect(() => {
    // Check if the Telegram Web App is available
    if (window.Telegram && window.Telegram.WebApp) {
      // Initialize the Telegram Web App
      window.Telegram.WebApp.ready();

      // Request to expand the app to full screen
      window.Telegram.WebApp.expand();
    }
  }, []);

  return (
    <StrictMode>
      <Provider store={store}>
        <Auth>
          <Router />
        </Auth>
      </Provider>
    </StrictMode>
  );
}

export default App;
