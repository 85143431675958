import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import info from "../../assets/info.svg";
import urls from "../../utils/imageUrls";
import { refetchProfile } from "../../redux/actions/Account";

import styles from "./styles.module.css";

export const Information = ({ bullets }) => {
  const { user } = useSelector(({ account }) => account);
  const { gameConfig } = useSelector(({ gameConfig }) => gameConfig);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userProgress = user?.data?.progress?.length;
  const userBullets = user?.data?.curBullets;
  const userBulletsLimit = gameConfig?.ammo?.[0]?.value ?? 100;

  useEffect(() => {
    let intervalID = null;
    clearInterval(intervalID);
    // if limit of the bullets is reached, returning
    if (userBullets === userBulletsLimit) {
      return;
    }
    // fetching bullets until the limit is reached
    intervalID = setInterval(() => {
      dispatch(refetchProfile());
    }, 60000);

    return () => {
      clearInterval(intervalID);
    };
  }, [userBullets, gameConfig]);

  const navigateHandler = () => {
    if (bullets) return;
    navigate("/info");
  };

  return (
    <div className={styles.wrapper} style={{ gap: bullets ? "10px" : "30px" }}>
      <div
        className={`${bullets ? "" : styles.infoIconContainer}`}
        onClick={navigateHandler}
      >
        <img
          className={styles.icon}
          src={bullets ? urls.bullets : info}
          alt=""
        />
      </div>
      <div className={styles.nums}>
        <span style={{ color: bullets && "#55F2E5" }}>
          {bullets ? userBullets || 0 : userProgress}
        </span>
        <span
          className={`${bullets ? styles.bulSecondPart : styles.secondPart}`}
        >
          {`/ ${bullets ? userBulletsLimit : userProgress || 0}`}
        </span>
      </div>
    </div>
  );
};
