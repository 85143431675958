import { GET_GAME_CONFIG } from "../constants/actionTypes";

const initialState = {
  gameConfig: {},
};

const GameConfig = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_GAME_CONFIG:
      return {
        gameConfig: payload,
      };

    default:
      return state;
  }
};

export default GameConfig;
