import { useLocation, useNavigate } from "react-router-dom";

import styles from "./styles.module.css";

import { mapa } from "./footer-data";

export const FooterNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {mapa.map((item, i) => {
        const activeIconHandler = location.pathname === item.path;
        const navigateHandler = () =>
          activeIconHandler ? null : navigate(item.path);
        return (
          <div key={i} className={styles.content} onClick={navigateHandler}>
            <div className={styles.iconContainer}>
              <img
                src={activeIconHandler ? item.activeIcon : item.icon}
                alt=""
              />
            </div>
            <div className={styles.name}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};
