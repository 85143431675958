import { useSelector } from "react-redux";
import centerThing from "../../../../assets/roll-circle2/centerThings.svg";

import styles from "./styles.module.scss";

const CenterThings = ({ isAnimationActive }) => {
  const { user } = useSelector(({ account }) => account);

  const circleAmount = Array(user?.data?.level)?.length;

  const widthHandler = () => {
    if (circleAmount === 1) return "25px";
    else if (circleAmount > 1) return "10px";
  };

  const currWidth = widthHandler();

  return (
    <div
      className={isAnimationActive ? styles.thingWrap : styles.thingWrapNone}
    >
      <div className={styles.iconContainer}>
        <img
          src={centerThing}
          alt=""
          className={styles.icon}
          style={{ "--width-var": currWidth }}
        />
      </div>
      <div className={styles.iconContainer}>
        <img
          src={centerThing}
          alt=""
          className={styles.icon}
          style={{
            transform: "rotate(180deg)",
            "--width-var": currWidth,
          }}
        />
      </div>
    </div>
  );
};

export default CenterThings;
