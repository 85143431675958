import { useSelector } from "react-redux";

import { RollCircle } from "../../../../components";

import styles from "./styles.module.scss";

const CircleTargets = ({ curProgress }) => {
  const { user } = useSelector(({ account }) => account);

  const circlesData = Array(user?.data?.level).fill(1);
  const circleAmount = Array(user?.data?.level)?.length;

  return (
    <div className={styles.container}>
      <div
        className={
          circleAmount >= 3 ? styles.rollWrapperGrid : styles.rollWrapper
        }
      >
        {circlesData?.map((_, i) => (
          <RollCircle key={i} index={i} curProgress={curProgress} />
        ))}
      </div>
    </div>
  );
};

export default CircleTargets;
